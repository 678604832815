import React from 'react';
import Layout from "../components/layout";
import {graphql} from 'gatsby';
import BackgroundImage from "gatsby-background-image";
import LogoBig from '../img/logobig.inline.svg'

export default (props) => (
    <BackgroundImage
        Tag="section"
        className={'bg-center w-full bg-repeat-y bg-cover'}
        fluid={props.data.heroImage.childImageSharp.fluid}
        backgroundColor={`#EBF7EB`}
    >
        <Layout>
            <div className={"sm:hidden h-full bg-primary-500 py-10"}
            >
                <div className={'mx-auto w-1/2'}>
                    <LogoBig/>
                </div>
            </div>
    </Layout>
    </BackgroundImage>
);


export const pageQuery = graphql`
query {
  heroImage: file(relativePath: { eq: "heinz.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 1900) {
       ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
}
`;

